import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import Button from '../Button/Button'
import { sendVoid } from '../../services/gatewayApi'

export default function ResendVoidButton({ transactionId, onDone }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleResendVoid = async () => {
    setIsLoading(true)

    try {
      await sendVoid(transactionId)

      toast.success(t('refundSuccess'))
      onDone?.()
    } catch (e) {
      console.log(e)
      toast.error(t('refundError'))
      onDone?.()
    }

    setIsLoading(false)
  }

  return (
    <Button isLoading={isLoading} disabled={isLoading} onClick={handleResendVoid}>
      {t('resendRefund')}
    </Button>
  )
}
